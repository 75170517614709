<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <base-info-card
        :title="title"
        :subtitle="subtitle"
        space="4"
        color="primary"
      />

      <base-text-field
        label="Name"
        :model="name"
        @update="setName($event)"
      />

      <base-email-field
        label="Email"
        :model="email"
        @update="setEmail($event)"
      />

      <base-textarea
        class="mb-6"
        label="Ihr Anliegen"
        :model="message"
        @update="setMessage($event)"
      />
    </v-form>
    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      target="_blank"
      @click="sendMail"
    >
      Abschicken
    </base-btn>
  </div>
</template>

<script>
  const Recipient = require('mailersend').Recipient
  const EmailParams = require('mailersend').EmailParams
  const MailerSend = require('mailersend')

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Teilen Sie mir Ihr Anliegen mit',
      },
    },

    data: () => ({
      name: '',
      email: '',
      message: '',
      valid: true,
    }),
    methods: {
      sendMail () {
        this.$refs.form.validate()
        console.log(this.name, this.message, this.email)

        const ms = new MailerSend({
          api_key:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjU4MDU4OWMxOTZlZmVlMDY2OTYyNTFkYWNlM2JiZTE2Y2FlYmY4ZTg3MGQxMzU1MWRiOTY3YThmY2FlNzFhNjYyNzA4MDA4MzVhYTE3M2IiLCJpYXQiOjE2NTg5OTQ3NzIuMjU0MzU5LCJuYmYiOjE2NTg5OTQ3NzIuMjU0MzYzLCJleHAiOjQ4MTQ2NjgzNzIuMjUwNzEyLCJzdWIiOiIzMzIwOCIsInNjb3BlcyI6WyJlbWFpbF9mdWxsIiwiZG9tYWluc19mdWxsIiwiYWN0aXZpdHlfZnVsbCIsImFuYWx5dGljc19mdWxsIiwidG9rZW5zX2Z1bGwiLCJ3ZWJob29rc19mdWxsIiwidGVtcGxhdGVzX2Z1bGwiLCJzdXBwcmVzc2lvbnNfZnVsbCIsInNtc19mdWxsIiwiZW1haWxfdmVyaWZpY2F0aW9uX2Z1bGwiXX0.lKn6G1mH9o574hCfpVT3_D83Rh3h3zzVfBJYUOdQDo8wAzo0rGDj3B5IhnHEyTrCO-S4PP753Sb9JJvnip9tJwOJgOHioE7c93FicZa0rsDIrwdknlm7VTmRjl_-CvQTeg1cq1otc2-qSllqDAi_jyVBNK_kb0VQjP7wyXplKWWkUzbbFUtg4XwMfHMUDeBnSREki_sCpLAAcifSGchIP0Uk-cYiLK5DJpotrO_j6DkD1Jd3ngrqtUowdiU-8jpgGhmQQuRkUWJrGrJKdpi-yK0jmlTpPn6-jrstxT9whg0n0OOgsDipaEpCL141vfXJCZGCn7LbdZPdiCWev1vW4swQ5yyUKYcpInNUM7M_qLBtYkwv_1hcSyj4bKDpOQQ78ltJDMaSth8joePpGX-atN9am8ddtZiZE2XpdQIVrG46UoIGqwWkQtfbUqUrxB4qHGn5QmC3eSMYS7fvn-NdEnMMbYv13Bksi_2j3pq_0jzbR8KTySrIzXjwJBjOr_X_pc3pyKwCyFoozHkOmHH9uFoKgmr-A2fPfWeH7k5FRzLStG46mxxfAYiR78ixShXbByNxut7ygrIFV0PZIn5ASj18dYKgPqSwY9accc_LVGnTd6S-ussn66eBlFEeORVN1BymGd350tSRv2uOMNlaGz7G9H-L7cTnpXLWB1dx40E',
        })
        const recipients = [new Recipient(this.email, 'monicabachmann.ch')]
        const variables = [
          {
            email: 'recipient@email.com',
            substitutions: [
              {
                var: 'name',
                value: this.name,
              },
              {
                var: 'message',
                value: this.message,
              },
            ],
          },
        ]

        const emailParams = new EmailParams()
          .setFrom('info@monicabachmann.ch')
          .setFromName('Monica Bachmann')
          .setRecipients(recipients)
          .setSubject('Sendebestätigung Nachricht monicabachmann.ch')
          .setTemplateId('3vz9dlemkrp4kj50')
          .setVariables(variables)

        ms.send(emailParams)
        /* this.axios.post(
          'https://api.mailersend.com/v1/email',
          {
            from: {
              email: 'info@monicabachmann.ch',
            },
            to: [
              {
                email: this.email,
              },
            ],
            variables: [
              {
                email: this.email,
                substitutions: [
                  {
                    var: 'name',
                    value: this.name,
                  },
                  {
                    var: 'message',
                    value: this.message,
                  },
                ],
              },
            ],
            template_id: '3vz9dlemkrp4kj50',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              Authorization:
                'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjU4MDU4OWMxOTZlZmVlMDY2OTYyNTFkYWNlM2JiZTE2Y2FlYmY4ZTg3MGQxMzU1MWRiOTY3YThmY2FlNzFhNjYyNzA4MDA4MzVhYTE3M2IiLCJpYXQiOjE2NTg5OTQ3NzIuMjU0MzU5LCJuYmYiOjE2NTg5OTQ3NzIuMjU0MzYzLCJleHAiOjQ4MTQ2NjgzNzIuMjUwNzEyLCJzdWIiOiIzMzIwOCIsInNjb3BlcyI6WyJlbWFpbF9mdWxsIiwiZG9tYWluc19mdWxsIiwiYWN0aXZpdHlfZnVsbCIsImFuYWx5dGljc19mdWxsIiwidG9rZW5zX2Z1bGwiLCJ3ZWJob29rc19mdWxsIiwidGVtcGxhdGVzX2Z1bGwiLCJzdXBwcmVzc2lvbnNfZnVsbCIsInNtc19mdWxsIiwiZW1haWxfdmVyaWZpY2F0aW9uX2Z1bGwiXX0.lKn6G1mH9o574hCfpVT3_D83Rh3h3zzVfBJYUOdQDo8wAzo0rGDj3B5IhnHEyTrCO-S4PP753Sb9JJvnip9tJwOJgOHioE7c93FicZa0rsDIrwdknlm7VTmRjl_-CvQTeg1cq1otc2-qSllqDAi_jyVBNK_kb0VQjP7wyXplKWWkUzbbFUtg4XwMfHMUDeBnSREki_sCpLAAcifSGchIP0Uk-cYiLK5DJpotrO_j6DkD1Jd3ngrqtUowdiU-8jpgGhmQQuRkUWJrGrJKdpi-yK0jmlTpPn6-jrstxT9whg0n0OOgsDipaEpCL141vfXJCZGCn7LbdZPdiCWev1vW4swQ5yyUKYcpInNUM7M_qLBtYkwv_1hcSyj4bKDpOQQ78ltJDMaSth8joePpGX-atN9am8ddtZiZE2XpdQIVrG46UoIGqwWkQtfbUqUrxB4qHGn5QmC3eSMYS7fvn-NdEnMMbYv13Bksi_2j3pq_0jzbR8KTySrIzXjwJBjOr_X_pc3pyKwCyFoozHkOmHH9uFoKgmr-A2fPfWeH7k5FRzLStG46mxxfAYiR78ixShXbByNxut7ygrIFV0PZIn5ASj18dYKgPqSwY9accc_LVGnTd6S-ussn66eBlFEeORVN1BymGd350tSRv2uOMNlaGz7G9H-L7cTnpXLWB1dx40E',
            },
          },
        ) */
      },
      setMessage (text) {
        this.message = text
      },
      setName (text) {
        this.name = text
      },
      setEmail (text) {
        this.email = text
      },
    },
  }
</script>
