<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Nehmen Sie mit mir Kontakt auf"
        pace="6"
      >
        Zu meinen Normalen Öffnungszeiten bin ich für Sie erreichbar
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Steinacker 6<br>9548 Matzingen',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+41 78 703 44 45',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'info@monicabachmann.ch',
        },
        {
          icon: 'mdi-clock-check-outline',
          title: 'Öffnungszeiten',
          text: 'Mo, 13:30  17:30<br>Di - Do, 08:30 - 12:00, 13:30 - 17:30<br>Fr, 08:00 - 12:00',
        },
      ],
    }),
  }
</script>
