<template>
  <v-hover v-slot="{ hover }">
    <v-img
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
      <v-expand-transition>
        <div
          v-if="hover"
          class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal"
          style="height: 100%;"
        >
          {{ text }}
        </div>
      </v-expand-transition>
    </v-img>
  </v-hover>
</template>

<script>
  export default {
    name: 'BaseImage',
    props: {
      text: String,
    },
  }
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  background-color: rgba(5, 11, 31, 0.8) !important;
  color: #aea599 !important;
}
</style>
