<template>
  <v-textarea
    v-model="text"
    outlined
    dense
    single-line
    v-bind="{
      rows: 6,
      ...$attrs,
    }"
    @change="$emit('update', text)"
  />
</template>

<script>
  export default {
    name: 'BaseTextarea',

    props: {
      initialvalue: String,
    },
    data () {
      return {
        text: this.initialvalue,
      }
    },
  }
</script>

<style lang="sass">
  //
</style>
