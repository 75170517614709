<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        src="./assets/img/MBLogoUnnamed.png"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      />

      <base-title
        size="body-1"
        space="4"
        title="Monica Bachmann"
        weight="regular"
      />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Steinacker 6<br>9548 Matzingen',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+41 78 703 44 45',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'info@monicabachmann.ch',
        },
      ],
    }),
  }
</script>
