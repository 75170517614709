<template>
  <v-text-field
    v-model="text"
    outlined
    dense
    single-line
    :rules="textfieldrules"
    required
    v-on="$listeners"
    @change="$emit('update', text)"
  >
    <slot
      slot="append-outer"
      name="append-outer"
    />
  </v-text-field>
</template>

<script>
  export default {
    name: 'BaseTextField',

    props: {
      model: String,
    },
    data () {
      return {
        text: this.model,
        textfieldrules: [
          v => !!v || 'Name ist notwendig',
          v => v.length <= 80 || 'Name darf nicht mehr als 80 Zeichen lang sein',
        ],
      }
    },
  }
</script>

<style lang="sass">
  //
</style>
