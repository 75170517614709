<template>
  <v-theme-provider :dark="dark">
    <v-hover v-slot="{ hover }">
      <v-container
        class="pa-0"
        :class="hover && features? 'elevation-10': ''"
      >
        <v-row
          :justify="justify"
          no-gutters
          :class="frameclasses"
          @click="navigate()"
        >
          <v-col
            v-if="icon"
            :class="`text-${align}`"
            cols="12"
            class="mb-4"
          >
            <base-icon :color="color">
              {{ icon }}
            </base-icon>
          </v-col>

          <v-col
            v-if="title || subtitle"
            :cols="callout ? 9 : 12"
          >
            <base-subtitle
              v-if="subtitle"
              :title="subtitle"
              space="1"
            />

            <base-title
              :title="title"
              class="text-uppercase"
              space="1"
            />

            <base-divider :color="color" />

            <base-body
              v-if="text || $slots.default"
              :text="text"
              space="6"
            >
              <slot />
            </base-body>
          </v-col>

          <v-col
            v-if="callout"
            cols="2"
          >
            <div
              class="text-h2 white--text text--lighten-2 font-weight-bold pr-8"
              v-text="callout"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-hover>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseInfoCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String,
      id: String,
      frame: Boolean,
      features: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      frameclasses () {
        if (this.frame) {
          return 'border'
        } else {
          return ''
        }
      },
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
    },
    methods: {
      navigate () {
        console.log(this.title)
        // First check if the current route is on landing page (or wherever your #foo is) then set the click function for it.
        if (this.$route.path !== '/') {
          this.$vuetify.goTo('#' + this.id)
        } else {
          this.$vuetify.goTo('#' + this.id)
        }
      },
    },
  }
</script>

<style>
.border{
  border: 1px solid white !important;
  padding: 10px;
  padding-top: 15px;
  cursor: pointer;
}
</style>
